export const scrollToDiv = (
  elID: string,
  scrollDuration = 1000,
  offset = 0
) => {
  if (typeof window !== `undefined`) {
    const rect = document.querySelector("#" + elID).getBoundingClientRect(),
      to = rect.top + window.pageYOffset - offset,
      element = document.scrollingElement || document.documentElement,
      start = element.scrollTop,
      change = to - start,
      startDate = +new Date(),
      easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      },
      animateScroll = function () {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(
          easeInOutQuad(currentTime, start, change, scrollDuration)
        );
        currentTime < scrollDuration
          ? requestAnimationFrame(animateScroll)
          : (element.scrollTop = to);
      };
    animateScroll();
  }
};

export const scrollToTop = (scrollDuration = 1000) => {
  if (typeof window !== `undefined`) {
    let cosParameter = window.scrollY / 2,
      scrollCount = 0,
      oldTimestamp = performance.now();
    let step = (newTimestamp) => {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
      if (scrollCount >= Math.PI) window.scrollTo(0, 0);
      if (window.scrollY === 0) return;
      window.scrollTo(
        0,
        Math.round(cosParameter + cosParameter * Math.cos(scrollCount))
      );
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    };
    window.requestAnimationFrame(step);
  }
};

export const scaleText = () => {
  const scalable = document.querySelectorAll<HTMLElement>(".scale--js"),
    margin = 0;
  for (let i = 0; i < scalable.length; i++) {
    const scalableContainer = scalable[i].parentNode as HTMLElement;
    scalable[i].style.transform = "scale(1)";
    const scalableContainerWidth = scalableContainer.offsetWidth - margin;
    const scalableWidth = scalable[i].offsetWidth;
    scalable[i].style.transform =
      "scale(" + (scalableContainerWidth + 5) / scalableWidth + ")";
    scalableContainer.style.height =
      scalable[i].getBoundingClientRect().height + "px";
  }
};

export const convertirNumeroArgentina = (n: number): string =>
  n.toLocaleString("es-AR", { minimumFractionDigits: 2 });

export const slugToTitle = (slug: string): string =>
  slug
    .replace(/-/g, " ")
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

export const transformCloudinaryImg = (img: string, opt: string): string => {
  return img.replace(/\/upload.*\/v/, `/upload/${opt},fl_progressive/v`);
};
