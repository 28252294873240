import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

type Timing = "linear" | "easeIn" | "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" | "backInOut" | "anticipate";

type SlideInDownProps = {
  delay?: number,
  duration?: number
  distance?: number
  timing?: Timing
}

const SlideInDown: React.FC<SlideInDownProps> = (props) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const list = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: props.distance || 70 }
  }

  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={controls}
      variants={list}
      transition={{ delay: props.delay || 0.1, duration: props.duration || .5, ease: props.timing || 'easeOut' }}
    >
      {props.children}
    </motion.div>
  )
}

export default SlideInDown