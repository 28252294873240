import React, { useEffect } from "react";
import { Video } from "../../../interfaces/projects";

const VideoLazy: React.FC<Video> = ({ poster, url, urlSm }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const width = document.documentElement.clientWidth;
      const lazyVideos = [].slice.call(
        document.querySelectorAll<HTMLElement>("video.lazy")
      );

      if ("IntersectionObserver" in window) {
        const lazyVideoObserver = new IntersectionObserver((entries) => {
          entries.forEach((video: any) => {
            if (video.isIntersecting) {
              for (let source in video.target.children) {
                const videoSource: any = video.target.children[source];
                if (
                  typeof videoSource.tagName === "string" &&
                  videoSource.tagName === "SOURCE"
                ) {
                  videoSource.src =
                    width <= 768
                      ? videoSource.dataset.srcsm
                      : videoSource.dataset.src;
                }
              }

              video.target.classList.remove("lazy");
              lazyVideoObserver.unobserve(video.target);
              playVideo(video.target);
            }
          });
        });

        lazyVideos.forEach((lazyVideo) => {
          lazyVideoObserver.observe(lazyVideo);
        });
      }
    }
  }, []);

  const playVideo = async (video): Promise<void> => {
    try {
      await video.load();
      await video.play();
    } catch (err) {
      console.warn("ERROR!:", err);
    }
  };

  return (
    <video
      autoPlay
      muted
      playsInline
      loop
      preload="none"
      className="lazy"
      poster={poster}
    >
      <source data-src={url} data-srcsm={urlSm || url} type="video/mp4" />
      Your browser does not support HTML video.
    </video>
  );
};

export default VideoLazy;
