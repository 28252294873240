import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

type Timing =
  | "linear"
  | "easeIn"
  | "easeOut"
  | "easeInOut"
  | "circIn"
  | "circOut"
  | "circInOut"
  | "backIn"
  | "backOut"
  | "backInOut"
  | "anticipate";

type SlideInLeftProps = {
  delay?: number;
  duration?: number;
  distance?: number;
  timing?: Timing;
};

const SlideInLeft: React.FC<SlideInLeftProps> = (props) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const getDistance = () => {
    if (typeof window !== `undefined`) {
      if (window.innerWidth < 767 && props.distance) {
        return -props.distance / 2;
      }

      return -70;
    }
  };

  const list = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: getDistance() },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={list}
      transition={{
        delay: props.delay || 0,
        duration: props.duration || 0.5,
        ease: props.timing || "easeOut",
      }}
    >
      {props.children}
    </motion.div>
  );
};

export default SlideInLeft;
