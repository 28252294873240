import React from "react";
import "./style.scss";

interface ScaledTextProps {
  text: String;
  color?: "primary" | "secondary" | "gray";
  textTransform?: "uppercase" | "lowercase" | "capitalize";
}

export const ScaledText: React.FC<ScaledTextProps> = ({
  text,
  color = "primary",
  textTransform = "uppercase",
}) => {
  return (
    <div className="scaled-text text-center">
      <div
        className={`scale--js font-book text-${textTransform} text-${color}`}
        id="scale--js"
      >
        {text}
      </div>
    </div>
  );
};
