import { navigate } from "gatsby";
import React from "react";
import "./contactButton.scss";

interface ContactButtonProps {
  projectName: string;
}

const ContactButton: React.FC<ContactButtonProps> = ({ projectName }) => {
  const goToContact = (project: string) => {
    const url = project ? `/contacto?proyecto=${project}` : "/contacto";
    navigate(url);
  };

  return (
    <a
      className={`cotact-button ${!projectName ? "contact-home" : ""}`}
      onClick={() => goToContact(projectName)}
    >
      <div className="label font-bold">Contacto</div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 899 467">
        <path
          fill="#E84925"
          d="M77 78c35-30 81-45 127-47 23-1 47 0 70 6a167 167 0 0 1 114 86c10 19 16 40 20 61h-90a110 110 0 0 0-35-56c-17-14-40-22-62-22-24-2-49 4-68 18-21 14-37 37-45 61-7 18-9 38-9 57-1 31 6 62 22 88 13 21 33 38 56 46s47 8 70 3a97 97 0 0 0 72-76h90c-4 22-11 44-22 64-9 16-21 32-35 44-28 25-64 40-101 45-48 6-98 0-141-22-25-14-48-33-65-57a263 263 0 0 1-28-204c10-36 31-70 60-95Zm486-21c24-13 51-21 78-24l11 74a110 110 0 0 0-84 62 180 180 0 0 0-10 124c5 17 13 33 25 47 13 16 30 28 49 34l22 5-14 74c-24-3-47-9-68-20a185 185 0 0 1-101-120c-12-43-12-89-1-132a193 193 0 0 1 93-124Zm148-24a207 207 0 0 1 110 46c29 26 50 60 60 98a255 255 0 0 1 0 137 191 191 0 0 1-152 136l-17 3-14-74c18-3 34-8 49-18 15-11 27-26 37-42 15-30 19-64 15-97-2-20-8-40-17-58-10-17-24-32-41-43-13-7-26-12-40-14l10-74Z"
        />
      </svg>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 74.58 74.58"
        width="80px"
      >
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".cls-1{fill:currentColor;}.cls-2{fill:none;stroke:currentColor;stroke-miterlimit:10;}",
            }}
          />
        </defs>
        <g id="Capa_2" data-name="Capa 2">
          <g id="Capa_1-2" data-name="Capa 1">
            <path
              className="cls-1"
              d="M40.78,44.9a.58.58,0,0,1,.79-.17,24.46,24.46,0,0,0,2.49,1.46,10.61,10.61,0,1,0,6.3-20.1,10.48,10.48,0,0,0-10.43,4,15.64,15.64,0,0,0-2.65,5.73c-.44,1.45-.93,2.89-1.41,4.33a3.23,3.23,0,0,1-.15.34.33.33,0,0,1-.61-.07h0A13.83,13.83,0,0,1,47.5,22.75,13.8,13.8,0,0,1,51.15,50a13.69,13.69,0,0,1-11.38-2.72c-.41-.32-.49-.56-.09-1a8.62,8.62,0,0,0,.78-1l.32-.47"
            />
            <path
              className="cls-1"
              d="M77 78c35-30 81-45 127-47 23-1 47 0 70 6a167 167 0 0 1 114 86c10 19 16 40 20 61h-90a110 110 0 0 0-35-56c-17-14-40-22-62-22-24-2-49 4-68 18-21 14-37 37-45 61-7 18-9 38-9 57-1 31 6 62 22 88 13 21 33 38 56 46s47 8 70 3a97 97 0 0 0 72-76h90c-4 22-11 44-22 64-9 16-21 32-35 44-28 25-64 40-101 45-48 6-98 0-141-22-25-14-48-33-65-57a263 263 0 0 1-28-204c10-36 31-70 60-95Zm486-21c24-13 51-21 78-24l11 74a110 110 0 0 0-84 62 180 180 0 0 0-10 124c5 17 13 33 25 47 13 16 30 28 49 34l22 5-14 74c-24-3-47-9-68-20a185 185 0 0 1-101-120c-12-43-12-89-1-132a193 193 0 0 1 93-124Zm148-24a207 207 0 0 1 110 46c29 26 50 60 60 98a255 255 0 0 1 0 137 191 191 0 0 1-152 136l-17 3-14-74c18-3 34-8 49-18 15-11 27-26 37-42 15-30 19-64 15-97-2-20-8-40-17-58-10-17-24-32-41-43-13-7-26-12-40-14l10-74Z"
            />
            <circle className="cls-2" cx="37.29" cy="37.29" r="36.79" />
          </g>
        </g>
      </svg> */}
    </a>
  );
};

export default ContactButton;
